<!--  -->
<template>
    <div class='container'>
        <van-nav-bar title="客户提起线下收款申请"> </van-nav-bar>
        <div class="main" :style="{ height: mainHeight + 'px' }">
            <!-- <div class="title">
                提请人：张旭儿 <span style="margin-left: 0.2rem;">团号：88</span>
            </div> -->
            <div class="card">
                <van-collapse :border="false" v-model="activeNames">
                    <van-collapse-item icon="user-o" title="付款详情" name="0">
                        <div class="goodsBox">
                            <div class="goodsBoxText1">
                                <p> 团号</p><span>{{ datas.planId }}¥</span>
                            </div>
                            <div class="goodsBoxText1">
                                <p>付款人：</p>
                                <van-image v-if="datas.payImage" @click="imageClick(datas.payImage)" fit="cover" round
                                    class="payImg" width="20" height="20" :src="datas.payImage" />
                                <span>{{ datas.payName }}</span>
                            </div>
                            <div class="goodsBoxText1">
                                <p> 付款金额：</p><span>{{ datas.payPrice }}¥</span>
                            </div>
                            <div class="goodsBoxText1">
                                <p>金额状态：</p> <span>{{ statusMap[datas.status] }} </span>
                            </div>
                            <div class="goodsBoxText1">
                                <p>付款备注：</p> <span>{{ datas.remark }}</span>
                            </div>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </div>
        </div>
        <div class="footer">
            <div class="footerBtnBox">
                <van-button v-if="datas.status === 0" @click="send" class="footerBtns" size="small" type="primary">
                    确认已到账，发送通知信息给客户</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { getUrlParams } from '@/utils/unit'
import PayApi from "@/components/Api/pay";
import { Toast, Dialog, Notify, ImagePreview } from 'vant';

export default {
    components: {},
    data() {
        return {
            activeNames: ['0'],
            statusMap: {
                0: "待确认是否到账",
                1: "金额已到账到余额",
                2: "已确认到账待结转到余额"
            },
            datas: {},
            mainHeight: 0,
        }
    },
    computed: {},
    watch: {},
    methods: {
        imageClick(imgUrl) {
            ImagePreview({
                images: [
                    imgUrl
                ],
                closeable: true,
            });
        },
        getDatas() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            let obj = {
                id: this.chunDatas.payId,
            }
            new PayApi("pay").selectDetail(obj).then(res => {
                if (res.status == 200) {
                    this.datas = res.data
                    setTimeout(() => {
                        const footerHeight = this.$el.querySelector('.footer').clientHeight;
                        this.mainHeight = window.innerHeight - footerHeight - 46;
                    })
                    Toast.clear();
                } else {
                    Toast(res.statusText);
                }
            })
        },
        send() {
            Dialog.confirm({
                title: '到账确认',
                message: '是否确认到账？',
            }).then(() => {
                Toast.loading({
                    message: '发送中...',
                    forbidClick: true,
                });
                new PayApi("pay").sendPayMessage({ id: this.chunDatas.payId }).then(res => {
                    if (res.status === 200) {
                        Notify({ type: 'success', message: '发送成功！' });
                        this.getDatas()
                    } else {
                        Notify({ type: 'danger', message: res.statusText });
                    }
                })
            })
        }
    },
    created() {

    },
    mounted() {
        this.chunDatas = {
            payId: getUrlParams('payId'),
        }
        this.getDatas()
    },
}
</script>
<style lang='scss' scoped>
.container {
    .main {
        padding: 0.2rem;
        overflow-y: scroll;
        .title {
                color: red;
                font-weight: bold;
                font-size: 0.3rem;
                margin-bottom: 0.1rem;
            }
        .card {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            padding: 0.2rem;
            border-radius: 0.1rem;
            margin-bottom: 0.3rem;

            

            .goodsBox {
                .goodsBoxText1 {
                    display: flex;
                    color: #333333;
                    font-size: 0.27rem;
                    font-family: SourceHanSansSC-bold;
                    font-weight: bold;

                    .payImg {
                        height: 0.27rem;
                        margin-right: 0.1rem;
                    }

                    p {
                        min-width: 1.4rem;
                        text-align: right;
                    }

                    span {
                        font-size: 0.25rem;
                        color: red;
                        font-size: 0.27rem;
                    }
                }


            }
        }
    }

    .footer {
        padding: 0.35rem;
        width: 100%;

        .footerBtnBox {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .footerBtns {
                width: 100%;
            }
        }
    }
}
</style>