var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("van-nav-bar", { attrs: { title: "客户提起线下收款申请" } }),
      _c(
        "div",
        { staticClass: "main", style: { height: _vm.mainHeight + "px" } },
        [
          _c(
            "div",
            { staticClass: "card" },
            [
              _c(
                "van-collapse",
                {
                  attrs: { border: false },
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                [
                  _c(
                    "van-collapse-item",
                    { attrs: { icon: "user-o", title: "付款详情", name: "0" } },
                    [
                      _c("div", { staticClass: "goodsBox" }, [
                        _c("div", { staticClass: "goodsBoxText1" }, [
                          _c("p", [_vm._v(" 团号")]),
                          _c("span", [_vm._v(_vm._s(_vm.datas.planId) + "¥")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "goodsBoxText1" },
                          [
                            _c("p", [_vm._v("付款人：")]),
                            _vm.datas.payImage
                              ? _c("van-image", {
                                  staticClass: "payImg",
                                  attrs: {
                                    fit: "cover",
                                    round: "",
                                    width: "20",
                                    height: "20",
                                    src: _vm.datas.payImage,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.imageClick(_vm.datas.payImage)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _c("span", [_vm._v(_vm._s(_vm.datas.payName))]),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "goodsBoxText1" }, [
                          _c("p", [_vm._v(" 付款金额：")]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.datas.payPrice) + "¥"),
                          ]),
                        ]),
                        _c("div", { staticClass: "goodsBoxText1" }, [
                          _c("p", [_vm._v("金额状态：")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.statusMap[_vm.datas.status]) + " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "goodsBoxText1" }, [
                          _c("p", [_vm._v("付款备注：")]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.datas.remark))]),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "footer" }, [
        _c(
          "div",
          { staticClass: "footerBtnBox" },
          [
            _vm.datas.status === 0
              ? _c(
                  "van-button",
                  {
                    staticClass: "footerBtns",
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.send },
                  },
                  [_vm._v(" 确认已到账，发送通知信息给客户")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }